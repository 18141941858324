import { Select } from "antd";
import _ from "lodash";
import { memo, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

const ProfessionalInfoForm = memo(function ProfessionalInfoForm({ registerStepB, handleSubmitStepB, errorsStepB, watchStepB, setValueStepB, controlStepB, triggerStepB, setRegisterStep, resetFieldStepB, registerUser, registrationLoader, watchStepA }) {
    // const navigate = useNavigate();

    const [skills, setSkills] = useState([])

    const [experiencedJobTitle, setExperiencedJobTitle] = useState("Previous")

    const watchEmployeeType = watchStepB("employeeType")
    const watchCurrentEmployment = watchStepB("currentEmployment")
    const watchUserType = watchStepA("userType")

    useMemo(() => {
        if(watchUserType === "Senior Citizen"){
            setValueStepB("employeeType", "Experienced")
            setValueStepB("experienceYears", 25)
        }
        if (watchEmployeeType === "Fresher") {
            setValueStepB("currentEmployment", "no")
            setValueStepB("experienceYears", 0)
        }
        if (watchCurrentEmployment === "no") {
            setExperiencedJobTitle("Previous")
        }
        if (watchCurrentEmployment === "yes") {
            setExperiencedJobTitle("Current")
        }
        const getSkills = async () => {
            const fetchURL = await fetch(`https://api.sairajobs.in/skills`)
            const fetchData = await fetchURL.json()
            setSkills(_.map(fetchData.result, el => { return { label: el, value: el } }))
        }
        getSkills()
    }, [watchEmployeeType, watchCurrentEmployment, watchUserType, setValueStepB])

    const numFloatPattern = /^\d+(?:\.\d{1,2})?$/ // eslint-disable-line no-useless-escape
    const jobTitlePattern = /^[A-Za-z0-9\s\-\/\+&(),.']*$/ // eslint-disable-line no-useless-escape

    return <form onSubmit={handleSubmitStepB(registerUser)} noValidate>
        <ol className="form-content" style={{ counterReset: "formCounter 6" }}>
            <li className="form-wrap">
                {/* <div className="num">7</div> */}
                <div className="content full label-text-wrap">
                    <div className="tag">I'm</div>
                    <div className="radio-wrap">
                        <label className="label">Experienced
                            <input type="radio" name="radio" {...registerStepB("employeeType", { required: true })} value="Experienced" />
                            <span className="checkmark"></span>
                        </label>
                        <label className="label">A Fresher
                            <input type="radio" name="radio" {...registerStepB("employeeType", { required: true })} value="Fresher" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">8</div> */}
                <div className={watchEmployeeType === "Fresher" ? "content min experience disabled" : "content min experience"}>
                    <div className={errorsStepB && errorsStepB.experienceYears ? "inputHolder has-error" : "inputHolder"}>
                        <div className="tag dflex align-items-center"><span>I have </span><input type="number" {...registerStepB("experienceYears", {
                            required: "Please provide your Year of Experience",pattern: {value: numFloatPattern,message: "Please provide valid Year of Experience"}, max: { value: 100, message: "😱 Are you a Ghoooooosssttttt!!!" }, min: { value: watchEmployeeType === "Fresher" ? 0 : 0.01, message: "Please enter your experience" }, disabled: watchEmployeeType === "Fresher" ? true : false
                        })} className="txt" step="0.01" /><span> Years of experience</span></div>
                        {(errorsStepB && errorsStepB.experienceYears) && <span className="form-error">{errorsStepB.experienceYears.message}</span>}
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">9</div> */}
                <div className={watchEmployeeType === "Fresher" ? "content min label-text-wrap disabled" : "content min label-text-wrap"}>
                    <div className="tag">Are you currently employed
                        <div className="radio-wrap no-bg">
                            <label className="label">Yes
                                <input type="radio" {...registerStepB("currentEmployment", { required: true, disabled: watchEmployeeType === "Fresher" ? true : false })} value="yes" />
                                <span className="checkmark"></span>
                            </label>
                            <label className="label no">No
                                <input type="radio" {...registerStepB("currentEmployment", { required: true, disabled: watchEmployeeType === "Fresher" ? true : false })} value="no" />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">10</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepB && errorsStepB.jobTitle ? "inputHolder has-error" : "inputHolder"}>
                        <input type="text" placeholder=" " {...registerStepB("jobTitle", { required: 'Please provide your title',pattern:{value:jobTitlePattern,message:'Please provide valid title'} })} className="txt" />
                        <label className="tag">{watchEmployeeType === "Fresher" ? "Prefered" : experiencedJobTitle} Job Title</label>
                        {
                            (errorsStepB && errorsStepB.jobTitle) && <span className="form-error">{errorsStepB.jobTitle.message}</span>
                        }
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">11</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepB && errorsStepB.technicalSkills ? "inputHolder select has-error" : "inputHolder select"}>
                        <Controller name="technicalSkills" control={controlStepB} rules={{ required: true }} render={
                            ({ field }) => <Select mode="tags" tokenSeparators={[',']} bordered={false} style={{ width: "100%", height: "50px", borderBottom: "1px solid #B9B9B9" }} options={skills} size="large" suffixIcon={<svg viewBox="0 0 15 7" style={{ width: "13px", height: "13px" }}><path d="M7.5 7c-.1 0-.2 0-.3-.1l-7-6C0 .7-.1.4.1.2.3 0 .6-.1.8.1l6.7 5.7L14.2.1c.2-.2.5-.2.7.1.1.2.1.5-.1.7l-7 6c-.1 0-.2.1-.3.1z" style={{ fill: "#1d252c" }} /></svg>} listItemHeight={10} listHeight={250} dropdownMatchSelectWidth={false} optionFilterProp={"label"} dropdownStyle={{ backgroundColor: "#EFF1F8" }} {...field} placement={"bottomLeft"} placeholder="Type and Press Enter" />
                        } />
                        <label htmlFor="field1" className="tag">Key Skills</label>
                        {
                            (errorsStepB && errorsStepB.technicalSkills) && <span className="form-error">Please provide your skills</span>
                        }
                    </div>
                </div>
            </li>
        </ol>
        <div className="btn-wrap dflex justify-content-center">
            <button type="button" className="btn secondary" onClick={() => setRegisterStep(true)} disabled={registrationLoader}>BacK</button>
            <button type="submit" className="btn" disabled={registrationLoader}>{registrationLoader ? <svg viewBox="0 0 50 50" className="btn-loader"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "save and continue"}</button>
        </div>
    </form>
})

export default ProfessionalInfoForm