import _ from "lodash"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

const Authenticate = () => {
    const navigate = useNavigate()
    const [ urlParams ] = useSearchParams()
    const authenticateUser = useCallback(async (token) => {
        const authURL = await fetch(`https://api.sairajobs.in/auth?token=${token}`)
        const authData = await authURL.json()
        /* console.log(authData) */
        if(authData.auth){
            /* console.log("Ivide Keri") */
            let url = `https://sairajobs.in/dist/auth.php?uid=${authData.uid}`
            if(!_.isEmpty(urlParams.get('redirectTo'))){
                url += `&redirectTo=${urlParams.get('redirectTo')}`
            }
            window.location.replace(url)
        } else {
            /* console.log("Ivide Kerilla") */
            const data = {
                redirectTo: urlParams.get('redirectTo')
            }
            let u =''
            if(!_.isEmpty(data.redirectTo)){
                u = new URLSearchParams(data).toString()
            }
            navigate(`/${!_.isEmpty(u) ? `?${u}` : ''}`)
        }
    },[navigate,urlParams])
    useEffect(() => {
        const token = localStorage.getItem('token')
        authenticateUser(token)
    },[authenticateUser])
    return <>
    <div className="container">
        <div className="dflex alignMiddle justifyMiddle" style={{height:"100vh"}}>
            <div className="loader">
                <svg x="0" y="0" viewBox="0 0 46 40" >
                    <path style={{fill:"#77cfe2"}} d="M41 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M29 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M17 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M5 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                </svg>
            </div>
        </div>
    </div>
    </>
}

export default Authenticate