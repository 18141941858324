import _ from "lodash"
import moment from "moment"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"

const Footer = () => {
    const sairaURL = "https://sairajobs.in/dist/"

    const [showThankyou, setShowThankyou] = useState(false)
    const [socialLinks, setSocialLinks] = useState({})

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            Email: ""
        }
    })

    const getSocialLinks = () => {
        fetch('http://api.sairajobs.in/social-links')
        .then(
            (res) => res.json()
        )
        .then(
            (result) => {
                // console.log(result)
                setSocialLinks(result)
            }
        )
    }

    useEffect(() => {
        let mounting = false
        if(!mounting){
            getSocialLinks()
        }
        return () => {
            mounting = true
        }
    }, [])

    const newsletterSubscribe = (data) => {
        /* console.log(data) */
        const formData = new FormData();
        formData.append('Email', data.Email)
        fetch("https://forms.zohopublic.in/SaiRaJobs/form/Subscribeform/formperma/MR08_lXWPREeVoJPQQhTtMHZAL02WJ4xSpZixsjN3fs/htmlRecords/submit", {
            method: "POST",
            body: formData
        })
            .then(() => {
                setShowThankyou(true)
            })
            .catch(() => {
                setShowThankyou(true)
            })
    }
    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/ // eslint-disable-line no-useless-escape
    return <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col2 logo-wrap">
                    <svg viewBox="0 0 110.9 29.4" role="img" className="footerlogo">
                        <clipPath id="SVGID_00000106865315919988428450000008407589772817495708_">
                            <path d="M71.9 7.6c7 .7 8 4.4 8 4.4 0-6.2 11.1-6.2 16.4-5.9V0C81.6-.6 79.9 4.7 79.8 6.9c-.5-3.4-4-4.7-7.9-5.1v5.8z" />
                        </clipPath>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#00afaa" }}
                            d="m71.9 7.6 3.5 4.4h-3.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#00afaa" }}
                            d="m71.9 7.5 3.7 4.5h-.2l-3.5-4.4z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#01aea9" }}
                            d="m71.9 7.3 3.8 4.7h-.1l-3.7-4.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#02aea9" }}
                            d="m71.9 7.2 3.9 4.8h-.1l-3.8-4.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#03ada8" }}
                            d="m71.9 7 4 5h-.1l-3.9-4.8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#04ada7" }}
                            d="M71.9 6.9 76 12h-.1l-4-5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#05aca7" }}
                            d="m71.9 6.7 4.3 5.3H76l-4.1-5.1z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#06aba6" }}
                            d="m71.9 6.6 4.4 5.4h-.1l-4.3-5.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#07aba6" }}
                            d="m71.9 6.4 4.5 5.6h-.1l-4.4-5.4z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#08aaa5" }}
                            d="m71.9 6.3 4.6 5.7h-.1l-4.5-5.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#09a9a4" }}
                            d="m71.9 6.1 4.8 5.9h-.2l-4.6-5.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0aa9a4" }}
                            d="m71.9 6 4.9 6h-.1l-4.8-5.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0ba8a3" }}
                            d="m71.9 5.8 5 6.2h-.1l-4.9-6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0ca8a2" }}
                            d="M71.9 5.7 77 12h-.1l-5-6.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0da7a2" }}
                            d="m71.9 5.5 5.2 6.5H77l-5.1-6.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0ea6a1" }}
                            d="m71.9 5.4 5.4 6.6h-.2l-5.2-6.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#0fa6a0" }}
                            d="m71.9 5.2 5.5 6.8h-.1l-5.4-6.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#10a5a0" }}
                            d="m71.9 5.1 5.6 6.9h-.1l-5.5-6.8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#11a59f" }}
                            d="m71.9 4.9 5.7 7.1h-.1l-5.6-6.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#12a49f" }}
                            d="m71.9 4.7 5.9 7.3h-.2l-5.7-7.1z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#13a39e" }}
                            d="m71.9 4.6 6 7.4h-.1l-5.9-7.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#14a39d" }}
                            d="M71.9 4.4 78 12h-.1l-6-7.4z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#15a29d" }}
                            d="m71.9 4.3 6.2 7.7H78l-6.1-7.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#16a19c" }}
                            d="m71.9 4.1 6.4 7.9h-.2l-6.2-7.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#17a19b" }}
                            d="m71.9 4 6.5 8h-.1l-6.4-7.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#18a09b" }}
                            d="m71.9 3.8 6.6 8.2h-.1l-6.5-8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#19a09a" }}
                            d="m71.9 3.7 6.7 8.3h-.1l-6.6-8.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1a9f99" }}
                            d="m71.9 3.5 6.8 8.5h-.1l-6.7-8.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1b9e99" }}
                            d="m71.9 3.4 7 8.6h-.2l-6.8-8.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1c9e98" }}
                            d="M71.9 3.2 79 12h-.1l-7-8.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1d9d97" }}
                            d="m71.9 3.1 7.2 8.9H79l-7.1-8.8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1e9d97" }}
                            d="m71.9 2.9 7.3 9.1h-.1l-7.2-8.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#1f9c96" }}
                            d="m71.9 2.8 7.5 9.2h-.2l-7.3-9.1z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#209b96" }}
                            d="m71.9 2.6 7.6 9.4h-.1l-7.5-9.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#219b95" }}
                            d="m71.9 2.5 7.7 9.5h-.1l-7.6-9.4z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#229a94" }}
                            d="m71.9 2.3 7.8 9.7h-.1l-7.7-9.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#239994" }}
                            d="m71.9 2.2 8 9.8h-.2l-7.8-9.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#249993" }}
                            d="M71.9 2 80 12h-.1l-8-9.8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#259892" }}
                            d="M71.9 1.9 80.1 12H80L71.9 2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#269892" }}
                            d="M71.9 1.7 80.2 12h-.1L71.9 1.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#279791" }}
                            d="M71.9 1.6 80.3 12h-.1L71.9 1.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#289690" }}
                            d="M71.9 1.4 80.5 12h-.2L71.9 1.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#299690" }}
                            d="M71.9 1.3 80.6 12h-.1L71.9 1.4z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2a958f" }}
                            d="M71.9 1.1 80.7 12h-.1L71.9 1.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2b958f" }}
                            d="M71.9.9 80.8 12h-.1L71.9 1.1z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2c948e" }}
                            d="M71.9.8 81 12h-.2L71.9.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2d938d" }}
                            d="M71.9.6 81.1 12H81L71.9.8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2e938d" }}
                            d="M71.9.5 81.2 12h-.1L71.9.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#2f928c" }}
                            d="M71.9.3 81.3 12h-.1L71.9.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#30918b" }}
                            d="M71.9.2 81.5 12h-.2L71.9.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#31918b" }}
                            d="m71.9 0 9.7 12h-.1L71.9.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#32908a" }}
                            d="M71.9-.1 81.7 12h-.1L71.9 0z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#339089" }}
                            d="M71.9-.3 81.8 12h-.1L71.9-.1z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#348f89" }}
                            d="m71.9-.4 10 12.4h-.1L71.9-.3z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#358e88" }}
                            d="M71.9-.6 82.1 12h-.2L71.9-.4v-.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#368e88" }}
                            d="M72.1-.6 82.2 12h-.1L71.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#378d87" }}
                            d="M72.2-.6 82.3 12h-.1L72.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#388d86" }}
                            d="M72.3-.6 82.4 12h-.1L72.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#398c86" }}
                            d="M72.4-.6 82.6 12h-.2L72.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3a8b85" }}
                            d="M72.6-.6 82.7 12h-.1L72.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3b8b84" }}
                            d="M72.7-.6 82.8 12h-.1L72.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3c8a84" }}
                            d="M72.8-.6 82.9 12h-.1L72.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3d8983" }}
                            d="M72.9-.6 83 12h-.1L72.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3e8982" }}
                            d="M73-.6 83.2 12H83L72.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#3f8882" }}
                            d="M73.2-.6 83.3 12h-.1L73-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#408881" }}
                            d="M73.3-.6 83.4 12h-.1L73.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#418781" }}
                            d="M73.4-.6 83.5 12h-.1L73.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#428680" }}
                            d="M73.5-.6 83.7 12h-.2L73.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#43867f" }}
                            d="M73.7-.6 83.8 12h-.1L73.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#44857f" }}
                            d="M73.8-.6 83.9 12h-.1L73.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#45847e" }}
                            d="M73.9-.6 84 12h-.1L73.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#46847d" }}
                            d="M74-.6 84.2 12H84L73.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#47837d" }}
                            d="M74.2-.6 84.3 12h-.1L74-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#48837c" }}
                            d="M74.3-.6 84.4 12h-.1L74.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#49827b" }}
                            d="M74.4-.6 84.5 12h-.1L74.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4a817b" }}
                            d="M74.5-.6 84.6 12h-.1L74.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4b817a" }}
                            d="M74.6-.6 84.8 12h-.2L74.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4c8079" }}
                            d="M74.8-.6 84.9 12h-.1L74.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4d8079" }}
                            d="M74.9-.6 85 12h-.1L74.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4e7f78" }}
                            d="M75-.6 85.1 12H85L74.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#4f7e78" }}
                            d="M75.1-.6 85.3 12h-.2L75-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#507e77" }}
                            d="M75.3-.6 85.4 12h-.1L75.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#517d76" }}
                            d="M75.4-.6 85.5 12h-.1L75.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#527c76" }}
                            d="M75.5-.6 85.6 12h-.1L75.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#537c75" }}
                            d="M75.6-.6 85.8 12h-.2L75.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#547b74" }}
                            d="M75.7-.6 85.9 12h-.1L75.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#557b74" }}
                            d="M75.9-.6 86 12h-.1L75.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#567a73" }}
                            d="M76-.6 86.1 12H86L75.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#577972" }}
                            d="M76.1-.6 86.2 12h-.1L76-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#587972" }}
                            d="M76.2-.6 86.4 12h-.2L76.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#597871" }}
                            d="M76.4-.6 86.5 12h-.1L76.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5a7871" }}
                            d="M76.5-.6 86.6 12h-.1L76.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5b7770" }}
                            d="M76.6-.6 86.7 12h-.1L76.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5c766f" }}
                            d="M76.7-.6 86.9 12h-.2L76.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5d766f" }}
                            d="M76.9-.6 87 12h-.1L76.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5e756e" }}
                            d="M77-.6 87.1 12H87L76.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#5f746d" }}
                            d="M77.1-.6 87.2 12h-.1L77-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#60746d" }}
                            d="M77.2-.6 87.4 12h-.2L77.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#61736c" }}
                            d="M77.3-.6 87.5 12h-.1L77.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#62736b" }}
                            d="M77.5-.6 87.6 12h-.1L77.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#63726b" }}
                            d="M77.6-.6 87.7 12h-.1L77.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#64716a" }}
                            d="M77.7-.6 87.8 12h-.1L77.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#65716a" }}
                            d="M77.8-.6 88 12h-.2L77.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#667069" }}
                            d="M78-.6 88.1 12H88L77.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#677068" }}
                            d="M78.1-.6 88.2 12h-.1L78-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#686f68" }}
                            d="M78.2-.6 88.3 12h-.1L78.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#696e67" }}
                            d="M78.3-.6 88.5 12h-.2L78.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6a6e66" }}
                            d="M78.5-.6 88.6 12h-.1L78.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6b6d66" }}
                            d="M78.6-.6 88.7 12h-.1L78.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6c6c65" }}
                            d="M78.7-.6 88.8 12h-.1L78.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6d6c64" }}
                            d="M78.8-.6 88.9 12h-.1L78.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6e6b64" }}
                            d="M78.9-.6 89.1 12h-.2L78.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#6f6b63" }}
                            d="M79.1-.6 89.2 12h-.1L78.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#716a63" }}
                            d="M79.2-.6 89.3 12h-.1L79.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#726962" }}
                            d="M79.3-.6 89.4 12h-.1L79.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#736961" }}
                            d="M79.4-.6 89.6 12h-.2L79.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#746861" }}
                            d="M79.6-.6 89.7 12h-.1L79.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#756860" }}
                            d="M79.7-.6 89.8 12h-.1L79.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#76675f" }}
                            d="M79.8-.6 89.9 12h-.1L79.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#77665f" }}
                            d="M79.9-.6 90.1 12h-.2L79.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#78665e" }}
                            d="M80.1-.6 90.2 12h-.1L79.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#79655d" }}
                            d="M80.2-.6 90.3 12h-.1L80.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7a645d" }}
                            d="M80.3-.6 90.4 12h-.1L80.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7b645c" }}
                            d="M80.4-.6 90.5 12h-.1L80.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7c635b" }}
                            d="M80.5-.6 90.7 12h-.2L80.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7d635b" }}
                            d="M80.7-.6 90.8 12h-.1L80.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7e625a" }}
                            d="M80.8-.6 90.9 12h-.1L80.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#7f615a" }}
                            d="M80.9-.6 91 12h-.1L80.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#806159" }}
                            d="M81-.6 91.2 12H91L80.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#816058" }}
                            d="M81.2-.6 91.3 12h-.1L81-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#826058" }}
                            d="M81.3-.6 91.4 12h-.1L81.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#835f57" }}
                            d="M81.4-.6 91.5 12h-.1L81.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#845e56" }}
                            d="M81.5-.6 91.7 12h-.2L81.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#855e56" }}
                            d="M81.6-.6 91.8 12h-.1L81.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#865d55" }}
                            d="M81.8-.6 91.9 12h-.1L81.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#875c54" }}
                            d="M81.9-.6 92 12h-.1L81.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#885c54" }}
                            d="M82-.6 92.1 12H92L81.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#895b53" }}
                            d="M82.1-.6 92.3 12h-.2L82-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8a5b53" }}
                            d="M82.3-.6 92.4 12h-.1L82.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8b5a52" }}
                            d="M82.4-.6 92.5 12h-.1L82.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8c5951" }}
                            d="M82.5-.6 92.6 12h-.1L82.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8d5951" }}
                            d="M82.6-.6 92.8 12h-.2L82.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8e5850" }}
                            d="M82.8-.6 92.9 12h-.1L82.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#8f584f" }}
                            d="M82.9-.6 93 12h-.1L82.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#90574f" }}
                            d="M83-.6 93.1 12H93L82.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#91564e" }}
                            d="M83.1-.6 93.3 12h-.2L83-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#92564d" }}
                            d="M83.2-.6 93.4 12h-.1L83.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#93554d" }}
                            d="M83.4-.6 93.5 12h-.1L83.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#94544c" }}
                            d="M83.5-.6 93.6 12h-.1L83.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#95544c" }}
                            d="M83.6-.6 93.7 12h-.1L83.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#96534b" }}
                            d="M83.7-.6 93.9 12h-.2L83.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#97534a" }}
                            d="M83.9-.6 94 12h-.1L83.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#98524a" }}
                            d="M84-.6 94.1 12H94L83.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#995149" }}
                            d="M84.1-.6 94.2 12h-.1L84-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9a5148" }}
                            d="M84.2-.6 94.4 12h-.2L84.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9b5048" }}
                            d="M84.4-.6 94.5 12h-.1L84.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9c5047" }}
                            d="M84.5-.6 94.6 12h-.1L84.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9d4f46" }}
                            d="M84.6-.6 94.7 12h-.1L84.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9e4e46" }}
                            d="M84.7-.6 94.8 12h-.1L84.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#9f4e45" }}
                            d="M84.8-.6 95 12h-.2L84.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a04d44" }}
                            d="M85-.6 95.1 12H95L84.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a14c44" }}
                            d="M85.1-.6 95.2 12h-.1L85-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a24c43" }}
                            d="M85.2-.6 95.3 12h-.1L85.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a34b43" }}
                            d="M85.3-.6 95.5 12h-.2L85.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a44b42" }}
                            d="M85.5-.6 95.6 12h-.1L85.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a54a41" }}
                            d="M85.6-.6 95.7 12h-.1L85.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a64941" }}
                            d="M85.7-.6 95.8 12h-.1L85.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a74940" }}
                            d="M85.8-.6 96 12h-.2L85.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a8483f" }}
                            d="M86-.6 96.1 12H96L85.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#a9483f" }}
                            d="M86.1-.6 96.2 12h-.1L86-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#aa473e" }}
                            d="m86.2-.6 10.1 12.5v.1h-.1L86.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ab463d" }}
                            d="m86.3-.6 10 12.4v.1L86.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ac463d" }}
                            d="m86.4-.6 9.9 12.2v.2L86.3-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ad453c" }}
                            d="m86.6-.6 9.7 12.1v.1L86.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ae443c" }}
                            d="m86.7-.6 9.6 11.9v.2L86.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#af443b" }}
                            d="m86.8-.6 9.5 11.8v.1L86.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b0433a" }}
                            d="M86.9-.6 96.3 11v.2L86.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b1433a" }}
                            d="m87.1-.6 9.2 11.5v.1L86.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b24239" }}
                            d="m87.2-.6 9.1 11.3v.2L87.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b34138" }}
                            d="m87.3-.6 9 11.2v.1L87.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b44138" }}
                            d="m87.4-.6 8.9 11v.2l-9-11.2z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b54037" }}
                            d="m87.5-.6 8.8 10.9v.1l-8.9-11z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b63f36" }}
                            d="m87.7-.6 8.6 10.7v.2L87.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b73f36" }}
                            d="M87.8-.6 96.3 10v.1L87.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b83e35" }}
                            d="m87.9-.6 8.4 10.4v.2L87.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#b93e35" }}
                            d="m88-.6 8.3 10.3v.1L87.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ba3d34" }}
                            d="m88.2-.6 8.1 10.1v.2L88-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#bb3c33" }}
                            d="m88.3-.6 8 10v.1L88.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#bc3c33" }}
                            d="m88.4-.6 7.9 9.8v.2l-8-10z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#bd3b32" }}
                            d="m88.5-.6 7.8 9.7v.1L88.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#be3b31" }}
                            d="m88.7-.6 7.6 9.5v.2L88.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#bf3a31" }}
                            d="m88.8-.6 7.5 9.3v.2L88.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c03930" }}
                            d="m88.9-.6 7.4 9.2v.1L88.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c1392f" }}
                            d="m89-.6 7.3 9v.2L88.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c2382f" }}
                            d="m89.1-.6 7.2 8.9v.1L89-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c3372e" }}
                            d="m89.3-.6 7 8.7v.2L89.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c4372e" }}
                            d="M89.4-.6 96.3 8v.1l-7-8.7z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c5362d" }}
                            d="m89.5-.6 6.8 8.4V8L89.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c6362c" }}
                            d="m89.6-.6 6.7 8.3v.1L89.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c7352c" }}
                            d="m89.8-.6 6.5 8.1v.2L89.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c8342b" }}
                            d="m89.9-.6 6.4 8v.1L89.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#c9342a" }}
                            d="m90-.6 6.3 7.8v.2l-6.4-8z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ca332a" }}
                            d="m90.1-.6 6.2 7.7v.1L90-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#cb3329" }}
                            d="m90.3-.6 6 7.5v.2L90.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#cc3228" }}
                            d="m90.4-.6 5.9 7.4v.1l-6-7.5z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#cd3128" }}
                            d="m90.5-.6 5.8 7.2v.2L90.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#ce3127" }}
                            d="m90.6-.6 5.7 7.1v.1L90.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#cf3026" }}
                            d="m90.7-.6 5.6 6.9v.2L90.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d02f26" }}
                            d="m90.9-.6 5.4 6.8v.1L90.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d12f25" }}
                            d="M91-.6 96.3 6v.2L90.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d22e25" }}
                            d="m91.1-.6 5.2 6.5V6L91-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d32e24" }}
                            d="m91.2-.6 5.1 6.3v.2L91.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d42d23" }}
                            d="m91.4-.6 4.9 6.2v.1L91.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d52c23" }}
                            d="m91.5-.6 4.8 6v.2L91.4-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d62c22" }}
                            d="m91.6-.6 4.7 5.9v.1l-4.8-6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d72b21" }}
                            d="m91.7-.6 4.6 5.7v.2L91.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d82b21" }}
                            d="m91.9-.6 4.4 5.5v.2L91.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#d92a20" }}
                            d="m92-.6 4.3 5.4v.1L91.9-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#da291f" }}
                            d="m92.1-.6 4.2 5.2v.2L92-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#db291f" }}
                            d="m92.2-.6 4.1 5.1v.1L92.1-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#dc281e" }}
                            d="m92.3-.6 4 4.9v.2L92.2-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#dd271e" }}
                            d="m92.5-.6 3.8 4.8v.1l-4-4.9z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#de271d" }}
                            d="M92.6-.6 96.3 4v.2L92.5-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#df261c" }}
                            d="m92.7-.6 3.6 4.5V4L92.6-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#e0261c" }}
                            d="m92.8-.6 3.5 4.3v.2L92.7-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#e1251b" }}
                            d="m93-.6 3.3 4.2v.1L92.8-.6z"></path>
                        <path
                            style={{ clipPath: "url(#SVGID_00000106865315919988428450000008407589772817495708_)", fill: "#e1251b" }}
                            d="M96.3 3.6 93-.6h3.3z"></path>
                        <path style={{ fill: "#fff" }}
                            d="M79.9 11.9h-.1c-.5-.3-2.9-1.3-7.9-1.3v18.2h8V11.9zM39 29.4c-1.7 0-3.4-.3-5-1-1.5-.6-2.8-1.6-3.7-2.9-1-1.4-1.5-3.1-1.4-4.8-.1-2.5 1-5 3-6.6 2-1.7 4.9-2.5 8.7-2.5H49v-.8c.1-1.4-.4-2.8-1.5-3.7-1-.7-2.9-1.1-5.9-1.1-3.2 0-6.3.5-9.3 1.5V1.9c1.6-.6 3.2-1 4.8-1.3 2-.3 4.1-.5 6.2-.5 4.3 0 7.6.9 9.9 2.6 2.3 1.7 3.5 4.5 3.5 8.2v17.8h-7l-.4-2.5c-1.2 1-2.6 1.8-4.1 2.3-2.1.7-4.1.9-6.2.9zm2.2-5.3c1.6 0 3.2-.3 4.7-.9C47 22.7 48.1 22 49 21v-4.4h-8.2c-3.1 0-4.7 1.3-4.7 3.8s1.7 3.7 5.1 3.7zm-14.5-7.3c-.1-.1-.1-.2-.2-.3l-.3-.3c-1.6-2.4-8.8-3.9-13.3-4.9-5.8-1.2-4.4-3-4.4-3s0-1.7 6-2c3.5-.1 7.1.5 10.4 1.7V1.5c-1.5-.4-3-.7-4.6-.9-1.9-.3-3.8-.4-5.8-.4C9.4.2 5.7.9 3.4 2.4 1.2 3.6-.1 6 0 8.5c-.1 2.1.8 4.1 2.4 5.4 2.1 1.4 4.5 2.5 7 3.1 2.9.8 6.1 1.4 7.6 1.8s2.2 1.1 2.2 2.1c0 .8-.6 1.5-1.4 1.6-1.9.3-3.9.5-5.9.5-2 0-3.9-.2-5.8-.5s-3.7-.7-5.5-1.3v6.6c1.8.5 3.5.9 5.4 1.1 2.3.3 4.7.5 7 .4 9.7 0 14.6-2.8 14.6-8.5.1-1.3-.2-2.7-.9-4zm80.7-14.1C104.9 1 102 .2 99 .2v6.1c1 .1 1.9.4 2.8.9 1.1.9 1.6 2.3 1.5 3.7v.8h-8.4c-3.9 0-6.8.8-8.7 2.5-2 1.6-3.1 4.1-3 6.6-.1 1.7.4 3.4 1.4 4.8.9 1.3 2.2 2.3 3.7 2.9 1.6.7 3.3 1 5 1 2.1.1 4.2-.2 6.1-.8 1.5-.5 2.9-1.3 4.1-2.3l.4 2.5h7V11c0-3.8-1.1-6.5-3.5-8.3zM103.2 21c-.8 1-1.9 1.7-3.1 2.2-1.5.6-3.1.9-4.7.9-3.4 0-5.1-1.2-5.1-3.7 0-2.6 1.6-3.8 4.7-3.8h8.2V21zm-42.9-9v16.8h8V10.7c-2.7.3-5.4.7-8 1.3zm0-3.4c2.6-.6 5.3-.9 8-1.1V1.7c-2.7.1-5.3.4-8 .9v6z">
                        </path>
                    </svg>
                    <ul>
                    {
                            _.keys(socialLinks).map(el => {
                                return <li key={el}>
                                    <Link aria-label={el} to={socialLinks[el]}>
                                        <span style={{fontSize:0}}>{el}</span>
                                        {
                                            el === "linkedin" && <svg viewBox="0 0 20 20" role="img"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM7.1 15.1H4.7V7.8h2.4v7.3zm-1-8.3h-.2c-.7 0-1.3-.5-1.3-1.2s.5-1.3 1.2-1.3H6c.7 0 1.3.5 1.4 1.2s-.6 1.2-1.3 1.3zm7.3 8.3v-3.9c0-1-.3-1.7-1.2-1.7-.6 0-1.1.4-1.2.9-.1.2-.1.4-.1.6v4.1H8.4V7.8h2.4v1c.5-.8 1.3-1.2 2.2-1.2 1.6 0 2.8 1 2.8 3.3v4.2h-2.4z" /></svg>
                                        }
                                        {
                                            el === "twitter" && <svg viewBox="0 0 20 20" role="img"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm2.5 10.3h-1.6v5.8H8.5v-5.8H7.3v-2h1.2V7c-.1-1.2.8-2.3 2.1-2.4h2.2v2h-1.3c-.3 0-.5.2-.5.5v1.3h1.8l-.3 1.9z" /></svg>
                                        }
                                        {
                                            el === "facebook" && <svg viewBox="0 0 20 20" role="img"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm2.5 10.3h-1.6v5.8H8.5v-5.8H7.3v-2h1.2V7c-.1-1.2.8-2.3 2.1-2.4h2.2v2h-1.3c-.3 0-.5.2-.5.5v1.3h1.8l-.3 1.9z" /></svg>
                                        }
                                        {
                                            el === "instagram" && <svg viewBox="0 0 504 504" role="img"><path d="M252 0C113 0 0 113 0 252s113 252 252 252 252-113 252-252S391 0 252 0zm163.4 304.9c0 60.9-49.6 110.5-110.5 110.5H199.1c-60.9 0-110.5-49.6-110.5-110.5V199.1c0-60.9 49.6-110.5 110.5-110.5h105.8c60.9 0 110.5 49.6 110.5 110.5v105.8z"/><path d="M326.6 143.1h33.9V177h-33.9zM312.4 252c0 33.3-27.1 60.4-60.4 60.4s-60.4-27.1-60.4-60.4c0-12.4 3.7-24.3 10.8-34.5 11.3-16.2 29.8-25.9 49.6-25.9s38.2 9.7 49.5 25.9c7.1 10.2 10.9 22.1 10.9 34.5z"/><path d="M393 222.3v82.6c0 48.6-39.5 88.1-88.1 88.1H199.1c-48.6 0-88.1-39.5-88.1-88.1v-82.6h63.8c-3.6 9.4-5.5 19.4-5.5 29.7 0 45.6 37.1 82.8 82.8 82.8s82.8-37.1 82.8-82.8c0-10.3-1.9-20.3-5.5-29.7H393z"/></svg>
                                        }
                                        {
                                            el === "youtube" && <svg viewBox="0 0 20 20" role="img"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm6 13c-.1.5-.6.9-1.1 1.1-1.6.2-3.3.3-4.9.2-1.6 0-3.3-.1-4.9-.3-.5 0-.9-.5-1.1-1-.2-1-.3-2-.3-3s.1-2 .3-3c.1-.5.6-1 1.1-1.1a40.28 40.28 0 0 1 9.8 0c.5.1.9.6 1.1 1.1.2 1 .3 2 .3 3-.1 1-.1 2-.3 3z" /><path d="m12 10-3.2 1.9V8.1z" /></svg>
                                        }
                                    </Link>
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className="col2">
                    <ul className="footer-nav">
                        <li><Link to={`${sairaURL}about-saira/`}>About Saira</Link></li>
                        <li><Link to={`${sairaURL}our-partners/`}>Our Partners</Link></li>
                        <li><Link to={`${sairaURL}blog/`}>Insights</Link></li>
                        <li><Link to={`${sairaURL}contact-us/`}>Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col2"></div>
                <div className="col6 newsletter">
                    <h2>Curated Career Content Delivered in a Free Newsletter. Sign Up!</h2>
                    <div className="subscribe">
                        {!showThankyou && <form onSubmit={handleSubmit(newsletterSubscribe)} noValidate aria-label="Newsletter Form">
                            <div className={errors && errors.Email ? "inputHolder has-error" : "inputHolder"}>
                                <input type="email" className="txt" placeholder=" " id="subscribeNewsletter" {...register("Email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: emailpattern,
                                        message: "Please provide a valid Email Address",
                                    },
                                })} />
                                <label htmlFor="subscribeNewsletter">name@website.com</label>
                                {
                                    (errors && errors.Email) && <span className="form-error">{errors.Email.message}</span>
                                }
                            </div>
                            <button type="submit" className="btn secondary dark">SUBSCRIBE NOW</button>
                        </form>}
                        {showThankyou && <div className="thank-you" style={{ display: "block" }}>Thanks for subscribing! Stay tuned for exciting updates.</div>}
                    </div>
                    <p className="copyright">{moment().format("YYYY")} © All rights reserved by Saira People Tech Solutions Pvt Ltd</p>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer