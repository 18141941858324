import React, { useEffect, useState } from "react";
import loginBg from "../assets/images/login-bg.png"

import { useForm } from "react-hook-form";
import Links from "./Links";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { Modal } from "antd";

const Login = () => {

    const navigate = useNavigate()
    const [ urlParams ] = useSearchParams()

    const [showError, setShowError] = useState("")
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [loginOTP, setLoginOTP] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const {register:otpRegister,handleSubmit:otpSubmit} = useForm()
    const {register:resetPasswordRegister,handleSubmit:resetPasswordSubmit, getValues:resetPasswordGetValues} = useForm()

    useEffect(() => {
        let mounting = false
        if(!mounting){
            document.title = "Login - Saira Jobs"
        }
        return () => {
            mounting = true
        }
    }, [])

    const authenticate = async data => {
        setShowError("")
        /* console.log(data); */
        const u = new URLSearchParams(data).toString();
        const loginURL = await fetch(`https://api.sairajobs.in/login?${u}`)
        const loginProcessData = await loginURL.json()
        /* console.log(loginProcessData) */
        if(loginProcessData.token){
            localStorage.setItem('token',loginProcessData.token)
            let redirectUrl = "https://sairajobs.in/dist/portal/"
            if(!_.isEmpty(urlParams.get('redirectTo'))){
                redirectUrl = `${urlParams.get('redirectTo')}`
            }
            navigate(`/authenticate?redirectTo=${redirectUrl}`)
        } else {
            setShowError(loginProcessData.message)
        }
    }

    const resetPasswordSubmission = async (data) => {
        const submissionData = {...data}
        delete submissionData.cpassword
        console.log(submissionData)
        const u = new URLSearchParams(submissionData).toString();
        const fetchURL = await fetch(`https://sairajobs.in/api/change-password?${u}`)
        const fetchData = await fetchURL.json()
        if(fetchData.matchedCount === 1 && fetchData.matchedCount === 1){
            setPasswordOpen(false)
            setPasswordReset(false)
        }
        if(fetchData.matchedCount === 1 && fetchData.matchedCount === 0){
            setSamePasswordError(true)
        }
    }

    const handlepaste = (e) => {
        const pastedData = e.clipboardData.getData('text/plain');
        console.log(/^\d*$/.test(pastedData))
        console.log(_.size(pastedData) === 6)
        if(_.size(pastedData) === 6 && /^\d*$/.test(pastedData)){
            _.forEach(pastedData.split(""),function(val,index){
                document.querySelectorAll(".input-outer-wrap input")[index].value = val
                document.querySelectorAll(".input-outer-wrap input")[index].focus()
            })
        } else {
            return
        }
    }

    const acceptOnlyNum = (e) => {
        const inputValue = e.target.value;
        if (!/^\d*$/.test(inputValue)) {
            e.target.value = '';
            return;
        }
    }

    const nextStep = (num, event) => {
        if(!["1","2","3","4","5","6","7","8","9","0"].includes(event.key)){
            return
        }
        if(event.key === "Backspace" && num !== 1){
            num = num === 0 ? 6 : num
            document.querySelectorAll(".input-outer-wrap input")[num - 2].focus()
            document.querySelectorAll(".input-outer-wrap input")[num - 2].select()
            return
        }
        if(event.key === "Backspace" && num === 1){
            document.querySelectorAll(".input-outer-wrap input")[0].focus()
            document.querySelectorAll(".input-outer-wrap input")[0].select()
            return
        }
        if(num === 0){
            document.querySelector(".verifi-popup-wrap .btn").focus()
            return
        }
        document.querySelectorAll(".input-outer-wrap input")[num].focus()
        document.querySelectorAll(".input-outer-wrap input")[num].select()
    }

    const verifyOTP = (data) => {
        console.log(_.values(data).join(""))
        fetch(`https://api.sairajobs.in/verify-email?email=${"test"}&type=verify&otp=${_.values(data).join("")}`)
        .then((res) => {
            if(res.error){
                setOtpError("Invalid OTP please try again")
                return
            }
            setPasswordReset(true)
        })
    }

    const resetPassword = () => {
        setPasswordOpen(true)
    }

    const otpLogin = () => {
        setLoginOTP(e => {
            return !e
        })
    }

    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/ // eslint-disable-line no-useless-escape

    return (
        <div className="login-wrap" style={{backgroundImage: `url(${loginBg})`}}>
            <div className="content">
                <h2 className="title">Sign in and Begin Your Journey</h2>
                <div className="register">Don't have an account? <Links target="/register" content="Register Now" /></div>
                <div className="find-job">
                    <form onSubmit={handleSubmit(authenticate)} className="noCol" aria-label="Login Form">
                        <div className={errors.email ? "inputHolder has-error" : "inputHolder"}>
                            <input type="text" placeholder=" " className="txt" id="usremail" {...register("email", { required: true, pattern: emailpattern })}/>
                            <label htmlFor="usremail" className="tag">Email ID</label>
                            {errors.email && <span className="form-error">Please enter your email</span>}
                        </div>
                        {!loginOTP && <div className={errors.password ? "inputHolder has-error" : "inputHolder"}>
                            <input type="password" placeholder=" " className="txt" id="usrpassword" {...register("password", { minLength: {value:8,message:'The password should have minimum 8 characters'} })} />
                            <label htmlFor="usrpassword" className="tag">Password</label>
                            {errors.password && <span className="form-error">{errors.password.message}</span>}
                        </div>}
                            {
                                !_.isEmpty(showError) && <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error">{showError}</span></div>
                            }
                        <div className="inputHolder btnwrapper">
                            {!loginOTP && <button type="button" className="forgot" onClick={() => resetPassword()}>Forgot Password?</button>}
                            <button type="submit" className="btn">Login</button>
                            <button type="button"  className="otp-login" onClick={() => otpLogin()}>Use {!loginOTP ? "OTP" : "Password"} to Login</button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal open={passwordOpen} footer={null} closable={false} maskStyle={{backgroundColor:"rgba(0,0,0,0.75)"}}>
            {!passwordReset && <form className="verifi-popup-wrap" id="verify" onSubmit={otpSubmit(verifyOTP)}>
                <h2>Check Your Email!</h2>
                <p>We have send 6 digit confirmation code to your email.<br />
                    Please enter the code before it expires.
                </p>
                <div className="input-outer-wrap">
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt1")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(1,e)} onPaste={handlepaste} />
                        <input type="text" {...otpRegister("opt2")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(2,e)} />
                        <input type="text" {...otpRegister("opt3")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(3,e)} />
                    </div>
                    <div className="txt"></div>
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt4")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(4,e)} />
                        <input type="text" {...otpRegister("opt5")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(5,e)} />
                        <input type="text" {...otpRegister("opt6")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(0,e)} />
                    </div>
                </div>
                <p><b>Resend Code in 20 Seconds</b></p>
                <p>Keep this window open while checking for your code.<br />
                    Remember to try your spam folder!
                </p>
                {
                    !_.isEmpty(otpError) && <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error">{otpError}</span></div>
                }
                <div className="btn-wrap justify-content-center">
                    <button className="btn">Verify</button>
                    <button type="button" className="btn red secondary" onClick={() => setPasswordOpen(false)}>Cancel</button>
                </div>
            </form>}
            {
                passwordReset && <form onSubmit={resetPasswordSubmit(resetPasswordSubmission)} className="verifi-popup-wrap" noValidate>
                    <h2>Reset Password</h2>
                    <div className={errors && errors.password ? "inputHolder has-error" : "inputHolder"}>
                        <input id="password" type="password" placeholder=" " className="txt" {...resetPasswordRegister('password', { minLength: {value:8,message:'The password should have minimum 8 characters'} })} />
                        <label htmlFor="password" className="tag">New Password</label>
                        {
                            (errors && errors.password) && <span className="form-error">{errors.password.message}</span>
                        }
                    </div>
                    <div className={errors && errors.cpassword ? "inputHolder has-error" : "inputHolder"}>
                        <input id="cnfPassword" type="password" placeholder=" " className="txt" {...resetPasswordRegister('cpassword', { validate: (value) => resetPasswordGetValues("password") === value || "Password doesn't match" })} />
                        <label htmlFor="cnfPassword" className="tag">Confirm Password</label>
                        {
                            (errors && errors.cpassword) && <span className="form-error">{errors.cpassword.message}</span>
                        }
                    </div>
                    {
                        samePasswordError && <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error">Please provide a password that is not similar to your previous password</span></div>
                    }
                    <div className="inputHolder btn-wrap justify-content-center">
                        <button className="btn">Change Password</button>
                    </div>
                </form>
            }
        </Modal>
        </div>
    )
}

export default Login