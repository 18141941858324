import { Select, Tooltip } from "antd";
import { useState } from "react";
import { Controller } from "react-hook-form";

const PersonalInfoForm = ({ registerStepA, errorsStepA, triggerStepA, isValidStepA, getValuesStepA, setRegisterStep, setDuplicateEmail, controlStepA, watchStepA }) => {

    const [duplicateEmailError, setDuplicateEmailError] = useState(false)

    const disability = [
        {
            disabled: true,
            value: "",
            label: "Select All Disabilities That Macthes You"
        },
        {
            value: "Blindness",
            label: "Blindness"
        },
        {
            value: "Low vision",
            label: "Low vision"
        },
        {
            value: "Leprosy Cured Persons",
            label: "Leprosy Cured Persons"
        },
        {
            value: "Hearing Impairment",
            label: "Hearing Impairment"
        },
        {
            value: "Locomotor Disability",
            label: "Locomotor Disability"
        },
        {
            value: "Dwarfism",
            label: "Dwarfism"
        },
        {
            value: "Intellectual Disability",
            label: "Intellectual Disability"
        },
        {
            value: "Mental Illness",
            label: "Mental Illness"
        },
        {
            value: "Autism Spectrum Disorder",
            label: "Autism Spectrum Disorder"
        },
        {
            value: "Cerebral Palsy",
            label: "Cerebral Palsy"
        },
        {
            value: "Muscular Dystrophy",
            label: "Muscular Dystrophy"
        },
        {
            value: "Chronic Neurological Conditions",
            label: "Chronic Neurological Conditions"
        },
        {
            value: "Specific Learning Disabilities",
            label: "Specific Learning Disabilities"
        },
        {
            value: "Multiple Sclerosis",
            label: "Multiple Sclerosis"
        },
        {
            value: "Speech and Language Disability",
            label: "Speech and Language Disability"
        },
        {
            value: "Thalassemia",
            label: "Thalassemia"
        },
        {
            value: "Hemophilia",
            label: "Hemophilia"
        },
        {
            value: "Sickle Cell Disease",
            label: "Sickle Cell Disease"
        },
        {
            value: "Acid Attack Victims",
            label: "Acid Attack Victims"
        },
        {
            value: "Parkinson's Disease",
            label: "Parkinson's Disease"
        }
    ]

    const proceed = async () => {
        setDuplicateEmailError(false)
        triggerStepA();
        const fetchURL = await fetch(`https://api.sairajobs.in/check-email?email=${getValuesStepA("email")}`)
        const fetchData = await fetchURL.json()
        if (fetchData.result.status) {
            setDuplicateEmailError(true)
            setDuplicateEmail(true)
        } else {
            if (isValidStepA) {
                setRegisterStep(false)
            }
        }
    }

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isShiftWithNumber = event.shiftKey && /^[0-9]+$/.test(keyValue);

        if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab' || (event.ctrlKey && keyValue === 'c') || (event.ctrlKey && keyValue === 'a') || (event.ctrlKey && keyValue === 'v') || (event.ctrlKey && keyValue === 'x')) {
            return;
        }

        // Allow only numbers (0-9)
        if (!/^[0-9]+$/.test(keyValue) || isShiftWithNumber) {
            event.preventDefault();
        }
    };


    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/ // eslint-disable-line no-useless-escape
    const namePattern = /^[A-Za-z\s.']*$/ // eslint-disable-line no-useless-escape

    return <>
        <ol className="form-content">
            <li className="form-wrap">
                {/* <div className="num">1</div> */}
                <div className="inputHolder">
                    <div className="tag">I'm a</div>
                    <div className="radio-wrap">
                        <Tooltip title="By selecting this option, you will have access to view only job opportunities specific to Senior Citizens.">
                            <span className="label">
                                <label htmlFor="userType1">Senior Citizen</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType1" value="Senior Citizen" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        <Tooltip title="By selecting this option, you will see only job opportunities specific to Women.">
                            <span className="label">
                                <label htmlFor="userType2">Back to Work Woman</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType2" value="Back to Work Woman" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        <Tooltip title="By selecting this option, you will view only job openings related to PWD (Persons with Disabilities).">
                            <span className="label">
                                <label htmlFor="userType3">Specially Abled (PWD)</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType3" value="Specially Abled (PWD)" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        {(errorsStepA && errorsStepA.firstName) && <span className="form-error">Please select a Category</span>}
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.firstName ? "inputHolder has-error" : "inputHolder"}>
                        <input type="text" placeholder=" " id="firstName" {...registerStepA("firstName", { required: true, pattern: namePattern })} className="txt" />
                        <label className="tag" htmlFor="firstName">First Name</label>
                        {(errorsStepA && errorsStepA.firstName) && <span className="form-error">Please provide your First Name</span>}
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.lastName ? "inputHolder has-error" : "inputHolder"}>
                        <input type="text" placeholder=" " id="lastName" {...registerStepA("lastName", { required: true, pattern: namePattern })} className="txt" />
                        <label className="tag" htmlFor="lastName">Last Name</label>
                        {(errorsStepA && errorsStepA.lastName) && <span className="form-error">Please provide your Last Name</span>}
                    </div>
                </div>
            </li>

            {watchStepA("userType") === "Specially Abled (PWD)" && <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.lastName ? "inputHolder select has-error" : "inputHolder select"}>
                        <Controller name="disability" control={controlStepA} rules={{ required: true }} render={
                            ({ field }) => <Select bordered={false} id="disability" style={{ width: "100%", height: "50px", borderBottom: "1px solid #B9B9B9" }} options={disability} size="large" suffixIcon={<svg viewBox="0 0 15 7" style={{ width: "13px", height: "13px" }}><path d="M7.5 7c-.1 0-.2 0-.3-.1l-7-6C0 .7-.1.4.1.2.3 0 .6-.1.8.1l6.7 5.7L14.2.1c.2-.2.5-.2.7.1.1.2.1.5-.1.7l-7 6c-.1 0-.2.1-.3.1z" style={{ fill: "#1d252c" }} /></svg>} listItemHeight={10} listHeight={250} dropdownMatchSelectWidth={false} optionFilterProp={"label"} dropdownStyle={{ backgroundColor: "#EFF1F8" }} {...field} placement={"bottomLeft"} mode="tags" tokenSeparators={[',']} maxTagCount="responsive" placeholder="Select All Disabilities That Macthes You" />
                        } />
                        <label htmlFor="disability" className="tag">Type of Disabilities</label>
                        {
                            (errorsStepA && errorsStepA.disability) && <span className="form-error">Please select your Disability</span>
                        }
                    </div>
                </div>
            </li>
            }

            <li className="form-wrap">
                {/* <div className="num">3</div> */}
                <div className="content min label-text-wrap">
                    <div className={(errorsStepA && errorsStepA.email) || duplicateEmailError ? "inputHolder has-error" : "inputHolder"}>
                        <input type="email" placeholder=" " id="email" {...registerStepA("email", { required: true, pattern: emailpattern })} className="txt" />
                        <label className="tag" htmlFor="email">Email</label>
                        {(errorsStepA && errorsStepA.email) && <>
                            <span className="form-error">Please provide a valid Email Address</span>
                        </>
                        }
                        {
                            duplicateEmailError && <span className="form-error">This Email address already exist with us. Please Login using this email</span>
                        }
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">4</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.password ? "inputHolder has-error" : "inputHolder"}>
                        <input type="password" placeholder=" " id="password" {...registerStepA("password", { required:'Please provide a password', minLength: {value:8,message:'The password should have minimum 8 characters'} })} className="txt" />
                        <label className="tag" htmlFor="password">Password</label>
                        {(errorsStepA && errorsStepA.password) && <span className="form-error">{errorsStepA.password.message}</span>}
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">5</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.cpassword ? "inputHolder has-error" : "inputHolder"}>
                        <input type="password" placeholder=" " id="cpassword" {...registerStepA("cpassword", { validate: (value) => getValuesStepA("password") === value || "Password doesn't match" })} className="txt" />
                        <label className="tag" htmlFor="cpassword">Confirm Password</label>
                        {(errorsStepA && errorsStepA.cpassword) && <span className="form-error">{errorsStepA.cpassword.message}</span>}
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">6</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.phoneNumber ? "inputHolder has-error" : "inputHolder"}>
                        <div className="prefix-input">
                            <span className="prefix">+91</span>
                            <input type="text" placeholder=" " id="phoneNumber" {...registerStepA("phoneNumber", { required: true, minLength: 10, maxLength: 10 })} className="txt" onKeyDown={handleKeyPress} maxLength={10} />
                        </div>
                        <label className="tag" htmlFor="phoneNumber">Mobile Number</label>
                        {(errorsStepA && errorsStepA.phoneNumber) && <span className="form-error">Please provide a valid Mobile Number</span>}
                    </div>
                </div>
            </li>
        </ol>
        <div className="btn-wrap dflex justify-content-center">
            <button type="button" className="btn" onClick={() => proceed()}>save and continue</button>
        </div>
    </>
}

export default PersonalInfoForm